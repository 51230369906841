import { useQuery } from '@apollo/react-hooks'
import { Dialog, DialogContent, Link, TablePagination } from '@material-ui/core'
import { StandardDivider } from 'components/common/StandardDivider'
import { Table } from 'components/common/Table'
import { Formik } from 'formik'
import {
  Audit,
  FindManyAdminAuditsQuery,
  FindManyAdminAuditsQueryVariables,
  GetAuditTypeQuery,
  GetAuditTypeQueryVariables
} from 'generated/graphql'
import { FINDS_MANY_AUDITS, GET_AUDIT_TYPE } from 'graphql/common'
import useRouter from 'hooks/useRouter'
import { keyBy, uniq } from 'lodash'
import React, { useState } from 'react'
import { isNull } from 'util'
import { Filter, Sorters } from 'utils/Constants'
import { formatDateInHHDDMMYY, toDisplayIsoDateTimeUtc } from 'utils/dates'
import * as yup from 'yup'
import { FormManageAudit } from './AuditFormFilter'
import { getTagIDsInMesssage, replacetagIDsText, useGetTaggedResources } from './helper'
import { emailValidator } from 'utils/tools'

export interface AuditItemInputs {
  EndDate?: Date
  StartDate?: Date
  PersonID?: string
  AuditType?: string
  EventType?: string
  ReferenceID?: string
  Email?: string
  ID?: string
}
const initialValues: AuditItemInputs = {
  EndDate: undefined,
  StartDate: undefined,
  PersonID: '',
  AuditType: '',
  EventType: '',
  ReferenceID: '',
  Email: '',
  ID: ''
}

interface AuditState {
  filter: Filter | null
  sorter: Sorters | null
  currentPage: number
  currentPageSize: number
}

type FormValueFieldName = 'EndDate' | 'StartDate' | 'PersonID' | 'AuditType' | 'EventType' | 'Email'

const auditValidationSchema = yup.object({
  EndDate: yup.date().nullable(),
  StartDate: yup.date().nullable(),
  PersonID: yup.number().typeError('Person ID must be a number'),
  AuditType: yup.object().nullable(),
  Email: yup
    .string()
    .nullable()
    .test('is-email-valid', 'Email address is not valid', emailValidator)
    .max(255, 'Email maximum 255 characters long'),
  ID: yup.number().typeError('ID must be a number')
})

const IGNORE_FILTER_KEYS = ['StartDate', 'EndDate', 'EventType']

export const ManageAudit = () => {
  const { history } = useRouter()
  const getTaggedResources = useGetTaggedResources()
  const [state, setState] = useState<AuditState>({
    currentPage: 1,
    currentPageSize: 10,
    filter: null,
    sorter: { sorters: [{ field: 'Audit.UTCtime', orderBy: 'DESC' }] }
  })
  const [HTML, setHTML] = useState<string>('')
  const { currentPage, currentPageSize, filter, sorter } = state
  const [audits, setAudits] = useState<FindManyAdminAuditsQuery | null>(null)

  const { loading } = useQuery<FindManyAdminAuditsQuery, FindManyAdminAuditsQueryVariables>(FINDS_MANY_AUDITS, {
    variables: {
      filter: {
        pagination: {
          pageSize: currentPageSize,
          pageNumber: currentPage
        },
        ...filter,
        ...sorter
      }
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ findManyAdminAudits }) => {
      setAudits({ findManyAdminAudits })
      const auditItems = findManyAdminAudits.items
      const tagIDs = uniq(
        auditItems.reduce(
          (ids: string[], item) => [...ids, ...getTagIDsInMesssage(item?.Message), `USER_#${item.PersonID}`],
          []
        )
      )
      const { data } = await getTaggedResources(tagIDs)
      const matchName = keyBy(data.getTaggedResources, 'ID')
      const formatterAudits = auditItems.map(item => ({
        ...item,
        PersonID: `<span> ${item.PersonID}, <span style="font-weight:bold;font-style:italic">${
          matchName[`USER_#${item.PersonID}`]?.Name
        } </span></span>`,
        Message: item.AuditType.trim() !== 'EmailSent' ? replacetagIDsText(matchName, item.Message) : item.Message
      }))
      setAudits({
        findManyAdminAudits: {
          ...findManyAdminAudits,
          items: formatterAudits
        }
      })
    }
  })
  const { data: dataAuditTypes } = useQuery<GetAuditTypeQuery, GetAuditTypeQueryVariables>(GET_AUDIT_TYPE)
  const auditTypes = dataAuditTypes?.getAuditType || []

  const setFilterChange = (filterValues: AuditItemInputs) => {
    const filterChange: Filter = {
      operator: 'AND',
      filters: []
    }
    Object.keys(filterValues).forEach((key: string) => {
      if (IGNORE_FILTER_KEYS.indexOf(key) === -1 && !!filterValues[key as FormValueFieldName]) {
        filterChange.filters.push({
          op: 'EQ',
          field: `Audit.${key}`,
          values: [filterValues[key as FormValueFieldName]]
        })
      }
    })
    if (filterValues.StartDate) {
      filterChange.filters.push({
        op: 'GTE',
        field: 'Audit.UTCTime',
        values: [new Date(filterValues.StartDate).toISOString()]
      })
    }
    if (filterValues.EndDate) {
      filterChange.filters.push({
        op: 'LTE',
        field: 'Audit.UTCTime',
        values: [new Date(filterValues.EndDate).toISOString()]
      })
    }
    if (filterValues.EventType) {
      filterChange.filters.push({
        op: 'LIKE',
        field: 'Audit.AuditType',
        values: [filterValues.EventType]
      })
    }
    setState({ ...state, filter: filterChange, currentPage: 1 })
  }
  const setSortChange = (sortKey: string | '', orderType: string | '') => {
    let keyName = sortKey
    switch (sortKey) {
      case 'ID':
        keyName = `Audit.${sortKey}`
        break
      case 'EVENT':
        keyName = `Audit.AuditType`
        break
      case 'PERSON ID':
        keyName = `Audit.PersonID`
        break
      case 'EMAIL':
        keyName = `Audit.Email`
        break
      case 'TIMESTAMP UTC':
        keyName = `Audit.UTCTime`
        break
      default:
        break
    }
    if (sortKey !== '' && orderType !== '') {
      const sort = { field: keyName, orderBy: orderType.toLocaleUpperCase() }
      if (sorter) {
        const filterindex = sorter?.sorters?.findIndex(e => e.field === sort.field)
        if (!isNull(filterindex)) {
          const existing = sorter.sorters[filterindex]
          const newArray = sorter.sorters.concat()
          newArray.splice(filterindex, 1, Object.assign({}, existing, sort))
          setState({ ...state, sorter: { sorters: newArray }, currentPage: 1 })
        }
      } else {
        setState({ ...state, sorter: { sorters: [sort] }, currentPage: 1 })
      }
    }
  }

  const columns = [
    {
      title: 'ID',
      field: 'ID',
      width: '7%'
    },
    {
      title: 'EVENT',
      field: 'AuditType',
      width: '10%'
    },
    {
      title: 'PERSON',
      field: 'PersonID',
      width: '15%',
      render: (row: Audit) => {
        return <div dangerouslySetInnerHTML={{ __html: row.PersonID || '' }} />
      }
    },
    {
      title: 'EMAIL',
      field: 'Email'
    },
    {
      title: 'REFERENCE ID',
      field: 'ReferenceID',
      width: '10%'
    },
    {
      title: 'MESSAGE',
      field: 'Message',
      render: (row: Audit) => {
        return row.AuditType.trim() !== 'EmailSent' ? (
          <div className="word-break" dangerouslySetInnerHTML={{ __html: row.Message || '' }} />
        ) : (
          ''
        )
      },
      headerStyle: {
        minWidth: 500
      },
      cellStyle: {
        minWidth: 500
      }
    },
    {
      title: 'TIMESTAMP UTC',
      field: 'UTCTime',
      render: (row: Audit) => {
        return formatDateInHHDDMMYY(toDisplayIsoDateTimeUtc(new Date(row.UTCTime)))
      }
    },
    {
      title: 'HTML',
      field: 'Message',
      width: '7%',
      render: (row: Audit) => {
        if (row.AuditType.trim() === 'EmailSent') {
          return <Link onClick={() => setHTML(row.Message as string)}>View HTML</Link>
        }
        return ''
      }
    }
  ]

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={auditValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setFilterChange(values)
          history.push(`/manage-audit?q=${JSON.stringify(values)}`)
          setSubmitting(false)
        }}
      >
        {props => {
          return <FormManageAudit form={props} auditTypes={auditTypes} setFilterChange={setFilterChange} />
        }}
      </Formik>
      <StandardDivider />
      <Table
        isLoading={loading}
        columns={columns}
        data={(audits?.findManyAdminAudits?.items as Audit[]) || []}
        option={{ search: false, pageSize: currentPageSize }}
        onOrderChange={(columnIndex, orderDirection) => {
          const columnPath = columnIndex < 0 ? null : columns[columnIndex].title
          setSortChange(columnPath || '', orderDirection)
        }}
        components={{
          Pagination: props => {
            return (
              <TablePagination
                {...props}
                rowsPerPageOptions={[10, 20, 50]}
                rowsPerPage={currentPageSize}
                count={audits?.findManyAdminAudits.pagination?.total || 0}
                page={currentPage - 1}
                onChangePage={(e, page) => {
                  setState({ ...state, currentPage: page + 1 })
                }}
                onChangeRowsPerPage={e => {
                  props.onChangeRowsPerPage(e)
                  setState({ ...state, currentPageSize: Number(e.target.value) })
                }}
              />
            )
          }
        }}
      />
      <Dialog open={!!HTML} onClose={() => setHTML('')} maxWidth={'md'} fullWidth>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: HTML || '' }} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ManageAudit
