import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { Box, DialogContentText, FormControlLabel, Grid, Radio, TablePagination } from '@material-ui/core'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { FormRadioGroup } from 'components/common/FormRadioGroup'
import { EditIcon, ListAltIcon, PersonIcon, SecurityIcon } from 'components/common/icons'
import { Table } from 'components/common/Table'
import {
  ExternalPerson,
  GetPagingExternalPeoplesQuery,
  GetPagingExternalPeoplesQueryVariables,
  ImpersonateUserQuery,
  ImpersonateUserQueryVariables,
  Person,
  Privilege
} from 'generated/graphql'
import { GET_PAGING_EXTERNAL_PEOPLES, IMPERSONATE_USER } from 'graphql/common'
import usePagination from 'hooks/usePagination'
import { useUserInfo } from 'hooks/useUserInfo'
import { flattenDeep, pick } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { isNull } from 'util'
import { BriefPortal, Portal } from 'utils/Constants'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import { customFilterAndSearchColumn } from 'utils/tools'
import { RoleAccessModal } from '../RoleAccess/RoleAccessModal'
import { EditUserModal } from './EditUserModal'
import { UserModal } from './UserModal'

interface Sorter {
  sorters: {
    field: string
    orderBy: string | 'ASC'
  }[]
}

export const External = () => {
  const { currentPageSize, onChangeRowsPerPage, currentPage, setCurrentPage, filter, onFilter } = usePagination(
    'pageSizeExternalUser'
  )
  const [selectedItem, setSelectedItem] = useState<ExternalPerson | null>(null)
  const [selectedRoleItem, setSelectedRoleItem] = useState<Person | null>(null)
  const [selectedEditUserItem, setSelectedEditUserItem] = useState<ExternalPerson | null>(null)
  const [skipQueryCognito, setSkipQueryCognito] = useState(true)
  const [sorter, setSorter] = useState<Sorter | null>(null)
  const [autoHideFeedback, setAutoHideFeedback] = useState<Feedback | null>(null)
  const [selectedImpersonateUser, setSelectedImpersonateUser] = useState<ExternalPerson | null>(null)
  const [portal, setPortal] = useState(Portal.LinkedSite)

  const queryHookOptions = useMemo(
    () => ({
      variables: {
        filter,
        pagination: {
          pageSize: currentPageSize,
          pageNumber: currentPage
        }
      },
      notifyOnNetworkStatusChange: true
    }),
    [currentPageSize, currentPage, filter]
  )

  const { loading, data, refetch: refetchPersonList } = useQuery<
    GetPagingExternalPeoplesQuery,
    GetPagingExternalPeoplesQueryVariables
  >(GET_PAGING_EXTERNAL_PEOPLES, queryHookOptions)

  const [impersonateUser] = useLazyQuery<ImpersonateUserQuery, ImpersonateUserQueryVariables>(IMPERSONATE_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setAutoHideFeedback(getSuccessFeedback('Impersonate User has been updated successfully.'))
      window.open(
        `${data?.impersonateUser?.RedirectUrl}?impersonateUser=${encodeURIComponent(
          JSON.stringify(pick(data.impersonateUser, ['IdToken', 'AccessToken', 'RefreshToken', 'ExpiresIn']))
        )}`,
        '_blank'
      )
    },
    onError: error => {
      setAutoHideFeedback(getErrorFeedback(error.message))
    }
  })

  const onCloseModal = () => {
    setSkipQueryCognito(true)
    setSelectedItem(null)
  }

  const onCloseModalManageRole = () => {
    setSelectedRoleItem(null)
  }

  const onCloseModalEditUser = () => {
    setSelectedEditUserItem(null)
  }

  const columns = [
    {
      title: 'Name',
      field: 'Firstname',
      render: (row: ExternalPerson) => `${row.person.Firstname} ${row.person.Lastname}`,
      customFilterAndSearch: (filter: string, rowData: ExternalPerson) =>
        customFilterAndSearchColumn(
          filter,
          rowData,
          (row: ExternalPerson) => `${row.person.Firstname} ${row.person.Lastname}`
        )
    },
    {
      title: 'Supplier',
      field: 'supplier.Name'
    },
    {
      title: 'Email',
      field: 'person.Email'
    },
    {
      title: 'Mobile',
      field: 'person.Phone'
    },
    {
      title: 'Status',
      render: (row: ExternalPerson) => (row.person.IsActive ? 'Active' : 'Inactive')
    }
  ]

  const setSortChange = (sortKey: string | '', orderType: string | '') => {
    let keyName = sortKey
    switch (sortKey) {
      case 'Email':
        keyName = `Person.${sortKey}`
        break
      case 'Name':
        keyName = `Person.Firstname`
        break
      case 'Company':
        keyName = `company.Name`
        break
      case 'Employee status':
        keyName = `Person.IsActive`
        break
      case 'Mobile':
        keyName = `Person.Phone`
        break
      case 'GeotrakID':
        keyName = `ExternalPerson.GeotrakID`
        break
      case 'WorkdayID':
        keyName = `ExternalPerson.WorkdayID`
        break
      default:
        break
    }

    if (sortKey !== '' && orderType !== '') {
      const sort = { field: keyName, orderBy: orderType.toLocaleUpperCase() }
      if (sorter) {
        const filterindex = sorter?.sorters?.findIndex(e => e.field === sort.field)
        if (!isNull(filterindex)) {
          const existing = sorter.sorters[filterindex]
          const newArray = sorter.sorters.concat()
          newArray.splice(filterindex, 1, Object.assign({}, existing, sort))
          setSorter({ sorters: newArray })
          setCurrentPage(1)
        }
      } else {
        setSorter({ sorters: [sort] })
        setCurrentPage(1)
      }
    }
  }
  const { personInfoData } = useUserInfo()

  const privileges: Privilege[] = useMemo(
    () => personInfoData?.personInfo?.roles?.map((e: { privileges: Privilege[] }) => e.privileges),
    [personInfoData]
  )

  const canImpersonateUser = useMemo(() => {
    return !!flattenDeep(privileges)?.find((arr: { Name: string }) => arr.Name === 'CanImpersonate')
  }, [privileges])

  const onImpersonateUser = () => {
    if (selectedImpersonateUser) {
      impersonateUser({
        variables: {
          PersonID: Number(selectedImpersonateUser?.person?.ID),
          Source: portal === Portal.LinkedSite ? BriefPortal.LinkedSite : BriefPortal.LinkedOffice
        }
      })
    }
    setPortal(Portal.LinkedSite)
  }

  useEffect(() => {
    if (data?.getPagingExternalPeoples.items?.length) {
      const newSelectedRolePerson = data?.getPagingExternalPeoples.items.find(e => e.person.ID === selectedRoleItem?.ID)
      selectedRoleItem?.roles?.length !== newSelectedRolePerson?.person?.roles?.length &&
        newSelectedRolePerson &&
        setSelectedRoleItem(newSelectedRolePerson?.person as Person)
    }
  }, [data, selectedRoleItem])

  const handleClose = () => {
    setSelectedImpersonateUser(null)
    setPortal(Portal.LinkedSite)
  }

  const handleSuccessReEnableAccount = () => {
    refetchPersonList()
    selectedItem &&
      setSelectedItem({
        ...selectedItem,
        person: { ...selectedItem?.person, IsActive: !selectedItem?.person?.IsActive }
      })
  }

  return (
    <>
      <Grid item xs={12}>
        <Table<ExternalPerson>
          isLoading={loading}
          columns={columns}
          data={(data?.getPagingExternalPeoples.items as ExternalPerson[]) || []}
          option={{
            pageSize: currentPageSize
          }}
          actions={[
            {
              icon: () => <PersonIcon />,
              tooltip: 'Impersonate User',
              onClick: (e, rowData) => {
                if (!Array.isArray(rowData)) {
                  setSelectedImpersonateUser(rowData as ExternalPerson)
                }
              },
              hidden: !canImpersonateUser
            },
            {
              icon: () => <EditIcon />,
              tooltip: 'Edit Account',
              onClick: (e, rowData) => {
                if (!Array.isArray(rowData)) {
                  setSelectedEditUserItem(rowData as ExternalPerson)
                }
              }
            },
            {
              icon: () => <SecurityIcon />,
              tooltip: 'Manage Account',
              onClick: (e, rowData) => {
                if (!Array.isArray(rowData)) {
                  setSelectedItem(rowData as ExternalPerson)
                  setSkipQueryCognito(false)
                }
              }
            },
            {
              icon: () => <ListAltIcon />,
              tooltip: 'Manage Roles',
              onClick: (e, rowData) => {
                if (!Array.isArray(rowData)) {
                  setSelectedRoleItem(rowData.person as Person)
                }
              }
            }
          ]}
          onOrderChange={(columnIndex, orderDirection) => {
            const columnPath = columnIndex < 0 ? null : columns[columnIndex].title
            setSortChange(columnPath || '', orderDirection)
          }}
          onSearchChange={onFilter}
          components={{
            Pagination: props => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 20, 50]}
                  rowsPerPage={currentPageSize}
                  count={data?.getPagingExternalPeoples.pagination?.total || 0}
                  page={currentPage - 1}
                  onChangePage={(_, page) => {
                    setCurrentPage(page + 1)
                  }}
                  onChangeRowsPerPage={event => {
                    onChangeRowsPerPage(event, props)
                  }}
                />
              )
            }
          }}
        />
      </Grid>
      {selectedItem && (
        <UserModal
          selectedItem={selectedItem}
          onCloseModal={onCloseModal}
          onRefetch={refetchPersonList}
          skipQueryCognito={skipQueryCognito}
          setAutoHideFeedback={setAutoHideFeedback}
          handleSuccessReEnableAccount={handleSuccessReEnableAccount}
        />
      )}

      {selectedRoleItem && (
        <RoleAccessModal
          selectedRoleItem={selectedRoleItem}
          onCloseModal={onCloseModalManageRole}
          refetchQuery={GET_PAGING_EXTERNAL_PEOPLES}
          refetchOption={queryHookOptions}
          isExternalRole
        />
      )}

      {selectedEditUserItem && (
        <EditUserModal
          selectedItem={selectedEditUserItem}
          onCloseModal={onCloseModalEditUser}
          onRefetch={refetchPersonList}
        />
      )}
      {!!autoHideFeedback && (
        <AutoHideSnackBar
          autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
          handleClose={() => setAutoHideFeedback(null)}
          message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
          severity={autoHideFeedback?.type}
          open={!!autoHideFeedback}
        />
      )}
      {selectedImpersonateUser && (
        <ConfirmDialog
          buttonText="Yes"
          handleClose={handleClose}
          open={!!selectedImpersonateUser}
          onConfirm={onImpersonateUser}
          title="Impersonate User"
        >
          <FormRadioGroup
            row
            value={portal}
            onChange={e => {
              setPortal(e.target.value as Portal)
            }}
          >
            {[Portal.LinkedSite, Portal.LinkedOffice].map(portal => (
              <FormControlLabel key={portal} value={portal} control={<Radio />} label={portal} />
            ))}
          </FormRadioGroup>
          <DialogContentText>
            <Box mt={1}>
              {`Please confirm to start impersonating this user ${selectedImpersonateUser?.person?.Firstname} ${selectedImpersonateUser?.person?.Lastname}?`}
            </Box>
          </DialogContentText>
        </ConfirmDialog>
      )}
    </>
  )
}
